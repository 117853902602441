// import {useState } from 'react'
import styles from './Navbar.module.css'
import Instagram from './SVG/Instagram'
// import Hamburger from './SVG/Hamburger'

const iconProps = {
  height: '24px',
  width: '24px',
  color: 'white'
}

const Navbar = () => {
  // const [isOpen, setIsOpen] = useState(false)

  // const toggleMenu = () => {
  //   setIsOpen(!isOpen)
  // }
  return (
    <header className={styles.header}>
      <nav className={styles.navbar}>
        {/* <div className={styles.navToggler}>
          <Hamburger {...iconProps} />
        </div> */}
        <ul className={styles.navLinks}>
          <li className={styles.linkHome}>
            <a href='/'>Piotr Opala</a>
          </li>
          <li className={styles.linkNavigation}>
            <a href='https://platoon.lnk.to/inceptio-piotr-opala!share'>Listen Now</a>
            <a href='#About'>About</a>
            <a href='https://platoon.lnk.to/inceptio-piotr-opala!share'>
              Works
            </a>
          </li>
          <li className={styles.linkSocials}>
            <p
              style={{
                color: 'grey',
              }}
            >
              Contact:{' '}
              <a href='mailto: piotropala.music@gmail.com'>
                piotropala.music@gmail.com
              </a>
            </p>
            <p
              style={{
                color: 'grey',
              }}
            >
              <a href='https://www.instagram.com/piotr__opala'>
              <Instagram {...iconProps} />
              </a>
            </p>
          </li>
        </ul>
      </nav>
    </header>
  )
}

export default Navbar
